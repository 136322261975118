<template>
  <div class="loading-text">
    <slot />{{ dots }}
  </div>
</template>

<script>
export default {
  name: 'LoadingText',
  data() {
    return {
      interval: null,
      dots: ''
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.dots.length == 0) {
        this.dots = '.'
      } else if (this.dots.length == 1) {
        this.dots = '..'
      } else if (this.dots.length == 2) {
        this.dots = '...'
      } else {
        this.dots = ''
      }
    }, 500)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>
