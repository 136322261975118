import Vue from 'vue'
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import web3 from "../services/web3Service";
import store from "../store";

class Wallet {
  constructor() {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: process.env.VUE_APP_INFURA_ID,
        },
      },
    };

    this._web3Modal = new Web3Modal({
      network: process.env.VUE_APP_NETWORK_NAME,
      cacheProvider: true,
      providerOptions,
    });

    if (this._web3Modal.cachedProvider) {
      this.connect()
    }
  }

  async connect() {
    const provider = await this._web3Modal.connect();

    web3.setProvider(provider);

    provider.on("accountsChanged", this._onAccountsChanged.bind(this));
    provider.on("chainChanged", this._onChainChanged.bind(this));

    await Promise.all([
      this._getCurrentNetworkId(),
      this._getaccountAddress()
    ]);
  }

  disconnect() {
    this._web3Modal.clearCachedProvider()
    window.location.reload()
  }

  async switchNetwork() {
    await web3.currentProvider.request({
      method: 'wallet_switchEthereumChain',
      params: [
        {
          chainId: this._getNetworkIdHex(process.env.VUE_APP_NETWORK_ID)
        }
      ]
    })
  }

  _getNetworkIdHex(networkId) {
    return `0x${parseInt(networkId).toString(16)}`
  }

  async _getCurrentNetworkId() {
    const networkId = await web3.eth.getChainId();

    if (parseInt(networkId) == process.env.VUE_APP_NETWORK_ID) {
      store.commit("SET_NETWORK_ID", parseInt(networkId));
    } else {
      // try {
        // // await this.switchNetwork()
      // } catch (err) {
        // this._showNetworkIdError()
      // }
    }
  }

  async _getaccountAddress() {
    const accounts = await web3.eth.getAccounts();

    if (accounts.length) {
      store.commit("SET_ACCOUNT_ADDRESS", accounts[0]);
    }
  }

  _onAccountsChanged(accounts) {
    if (accounts.length) {
      store.commit("SET_ACCOUNT_ADDRESS", accounts[0]);
    } else {
      store.commit("SET_ACCOUNT_ADDRESS", null);
      this.disconnect();
    }
  }

  _onChainChanged(networkId) {
    if (parseInt(networkId) == process.env.VUE_APP_NETWORK_ID) {
      store.commit("SET_NETWORK_ID", parseInt(networkId));
    } else {
      store.commit("SET_NETWORK_ID", null);
      // this._showNetworkIdError()
    }
  }

  _showNetworkIdError() {
    Vue.$toast.error(`The selected network is invalid. Please select Ethereum ${process.env.VUE_APP_NETWORK_NAME} and try again.`)
  }
}

export default {
  install(Vue) {
    Vue.prototype.$wallet = new Wallet();
  }
};
