import web3 from './web3Service'

export default class BaseContractService {
  constructor(contractAddress, abi) {
    this.contract = new web3.eth.Contract(abi, contractAddress)
  }

  totalSupply() {
    return this.contract.methods.totalSupply().call()
  }

  balanceOf(account) {
    return this.contract.methods.balanceOf(account).call()
  }

  ownerOf(tokenId) {
    return this.contract.methods.ownerOf(tokenId).call()
  }

  balanceOfContract(contractAddress) {
    return web3.eth.getBalance(contractAddress)
  }

  async estimateGas(action, options) {
    const gas = await action
      .estimateGas(options)
      .then(e => e)
      .catch(e => {
        console.error(this._handleRPCError(e))
        throw { message: this._handleRPCError(e) }
      })

    options = { ...options, gas: parseInt(1.2 * gas) }

    return action.send(options)
  }

  _handleRPCError(error) {
    const parsedError = JSON.stringify(error.message)

    if (parsedError.includes("execution reverted: ")) {
      const rgx = /(?:execution reverted: )(.*)(?=\\n{)/g
      const rgxResult = rgx.exec(parsedError)
      return Array.isArray(rgxResult) ? rgxResult[0] : rgxResult
    }

    if (typeof error.message === "string" || error.message instanceof String) {
      return {
        message: error.message
      }
    }

    return "Unknown error"
  }
}
