import Vue from 'vue'
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

let latestNetworkErrorTime = null

axios.interceptors.response.use(response => response, err => {
  if (!latestNetworkErrorTime || (Date.now() - latestNetworkErrorTime) >= 1000) {
    if (!err.response && !(err instanceof axios.Cancel)) {
      Vue.prototype.$toast.error('We were unable to complete your request. Please check your connection and try again.')
    } else if (err.response && err.response.status >= 500) {
      Vue.prototype.$toast.error('An unexpected error has ocurred.')
    }

    latestNetworkErrorTime = Date.now()
  }

  return Promise.reject(err)
})

export default axios
