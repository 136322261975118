<template>
  <a href="#" target="_blank">
    <div id="dvd"></div>
  </a>
</template>

<script>
export default {
  name: 'Floating',
  mounted() {
    let x = 0;
    let y = 0;
    let dirX = 1;
    let dirY = 1;
    let speed = 2;
    let dvd = document.getElementById("dvd");
    let dvdWidth = dvd.clientWidth;
    let dvdHeight = dvd.clientHeight;

    function animate() {
      const screenHeight = document.body.clientHeight;
      const screenWidth = document.body.clientWidth;

      if (y + dvdHeight + 3 >= screenHeight || y < 0) {
        dirY *= -1;
      }

      if (x + dvdWidth + 3 >= screenWidth || x < 0) {
        dirX *= -1;
      }

      x += dirX * speed;
      y += dirY * speed;

      dvd.style.left = x + "px";
      dvd.style.top = y + "px";

      window.requestAnimationFrame(animate);
    }

    window.requestAnimationFrame(animate);
  }
}
</script>

<style scoped>
  #dvd {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100px;
    width: 100px;
    background-image: url(/images/apecoin.gif);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
</style>
