// import http from './httpService'
import BaseContractService from './baseContractService'
import { toWei } from './web3Service'
import abi from '../abi/main.json'
import store from '../store'

class MainContractService extends BaseContractService {
  constructor() {
    super(process.env.VUE_APP_MAIN_CONTRACT_ADDRESS, abi)

    setTimeout(() => {
      this._setTotalSupply()
      this._setPublicSalesActive()

      setTimeout(() => {
        store.commit('SET_READY', true)
      }, 500)

      setInterval(() => {
        this._setTotalSupply()
        this._setPublicSalesActive()
      }, 5000)
    }, 500)
  }

  mint(amount, from) {
    const value = toWei(+process.env.VUE_APP_NORMAL_MINT_PRICE * amount)
    const options = {
      from,
      value,
    }

    const action = this.contract.methods.mint(amount)

    return this.estimateGas(action, options)
  }

  whitelistMint(signature, from) {
    const amount = 1
    const value = toWei(+process.env.VUE_APP_WHITELIST_MINT_PRICE * amount)
    const options = {
      from,
      value
    }

    const action = this.contract.methods.whitelistMint(amount, signature)

    return this.estimateGas(action, options)
  }

  isPublicSalesActive() {
    return this.contract.methods.isPublicSalesActive().call()
  }

  isWhitelistSalesActive() {
    return this.contract.methods.isWhitelistSalesActive().call()
  }

  publicSalesTimestamp() {
    return this.contract.methods.publicSalesTimestamp().call()
  }

  whitelistSalesTimestamp() {
    return this.contract.methods.whitelistSalesTimestamp().call()
  }

  totalNormalMintPerAccount(account) {
    return this.contract.methods.totalNormalMintPerAccount(account).call()
  }

  totalWhitelistMintPerAccount(account) {
    return this.contract.methods.totalWhitelistMintPerAccount(account).call()
  }

  async _setTotalSupply() {
    if (store.getters.isValidNetwork) {
      const total = await this.totalSupply()
      store.commit('SET_TOTAL_SUPPLY', parseInt(total))
    }
  }

  async _setPublicSalesActive() {
    if (store.getters.isValidNetwork) {
      const isPublicSalesActive = await this.isPublicSalesActive()
      store.commit('SET_PUBLIC_SALES_ACTIVE', isPublicSalesActive)
    }
  }

  async _setWhitelistSalesActive() {
    if (store.getters.isValidNetwork) {
      const isWhitelistSalesActive = await this.isWhitelistSalesActive()
      store.commit('SET_WHITELIST_SALES_ACTIVE', isWhitelistSalesActive)
    }
  }
}

export default new MainContractService()
