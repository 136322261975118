<template>
  <div class="sidemint">
    <img src="/images/side_f.gif">
    <div id="mint">
      <img src="/images/woman.gif">
      <img src="/images/crazy.gif">
      <img src="/images/crazy2.gif">
      <img src="/images/welcome.gif" style="margin-top: 48px">
      <img src="/images/woman.gif">
      <img src="/images/crazy.gif">
      <img src="/images/crazy2.gif">
      <br>
      <img src="/images/separator.gif">
      <img src="/images/separator.gif">
      <br>
      <br><a href="https://x.com/ApePePe_on_Ape" target="_blank">
      <img src="/images/twitter.gif" width="150px"></a>
      <br>
      <br><iframe src="https://xat.com/embed/chat.php#id=1730071098&gn=Apecoinnft" width="540" height="405" frameborder="0" scrolling="no"></iframe><br>
      <img src="/images/pepe-light.gif">
      <img src="/images/pepe-shadow.gif">
      <img src="/images/pepe-god.gif">
      <div class="d-block">PEPE MINT</div><br><img src="/images/clip.gif" width="300px"><br>
      <img src="/images/disk.gif" width="150px"><img src="/images/blood1.gif"><img src="/images/nes.gif" width="150px"><br>
      <img src="/images/arrow.gif"><img src="/images/arrow.gif"><img src="/images/arrow.gif"><img src="/images/arrow.gif"><img src="/images/arrow.gif"><img src="/images/arrow.gif">
      <img src="/images/arrow.gif"><img src="/images/arrow.gif"><img src="/images/arrow.gif"><img src="/images/arrow.gif"><img src="/images/arrow.gif"><img src="/images/arrow.gif">
      <div v-if="ready && !loading && !isServerDown && isConnected && isWhitelistSalesActive" class="account-address mb-2">
        <small class="d-block">
          
          {{ formattedAccountAddress }}
          
        </small>
        <small class="disconnect-wallet" @click="$wallet.disconnect()">
          Disconnect
          <img src="/images/unlock.gif">
        </small>
      </div>
      <br>
      <br>
      <br>
      <br>
      <button v-if="!isServerDown" type="button" class="mint-button" @click="handleMintButtonClick" :disabled="!ready || loading || soldOut || minting">
        <template v-if="!ready || loading">
          <v-loading-text></v-loading-text>
        </template>
        <template v-else-if="soldOut">
          Sold Out
        </template>
        <template v-else-if="!accountAddress">
          Connect Wallet
        </template>
        <template v-else-if="!this.isValidNetwork">
          Invalid Network
        </template>
        <template v-else-if="minting">
          <v-loading-text>Minting</v-loading-text>
        </template>
        <template v-else-if="isWhitelisted">
          Mint Now!
        </template>
        <template v-else-if="isWhitelistSalesActive">
          Mint Now!
        </template>
        <template v-else-if="isPublicSalesActive">
          Mint Now!
        </template>
        <template v-else>
          Sales is not active
        </template>
      </button>
      <div class="clearfix"></div>
      <div v-if="isServerDown">
        <div class="alert alert-error">
          <h2>Internal server error</h2>
          <p>Please reload the page and try again.</p>
        </div>
        <a href="/" class="reload-button d-inline-block mt-2">
          Reload
        </a>
      </div>
      <div v-else-if="soldOut" class="mt-2">
        All demons were minted!
      </div>
      <div v-else-if="accountAddress && !isValidNetwork" class="alert alert-error mt-4">
        Please ensure you're connected to the Ethereum {{ networkName }} before mint.
      </div>
      <div v-else-if="signature" class="alert alert-success mt-4">
        <img src="/images/pepe-engo.gif">
        You're in the whitelist!
        <img src="/images/pepe-green.gif">
      </div>
      <div v-else-if="mintLeft == 0" class="alert alert-error mt-4">
        100 Maximum ApePePes Minted.
      </div>
      <div v-else-if="isConnected && isPublicSalesActive && ready && !loading" class="mt-4">
        <div class="input-section">
          <input type="text" class="amount" name="amount" id="amount" v-model.number="amount" :disabled="minting" readonly>
          <div class="input-arrows ml-2">
            <button type="button" class="input-arrow input-arrow-up" @click="incrementAmount" :disabled="minting">
              /\
            </button>
            <button type="button" class="input-arrow input-arrow-down" @click="decrementAmount" :disabled="minting">
              \/
            </button>
          </div>
        </div>
        <div class="mint-price mt-4">
          {{ mintPrice }} APE
        </div>
      </div>
      <!-- <div v-else-if="isConnected && !isWhitelistSalesActive && ready && !loading" class="mt-2">
        Sales will start at 12:00:00 PM EST
      </div> -->
      <div v-if="ready && !isServerDown" class="supply mt-4">
        <small class="d-block">
          Units left
        </small>
        {{ unitsLeft | unit }}/{{ totalUnits | unit }}
        <br>
        <img src="/images/cross.gif"><img src="/images/wallet.gif"><a href="https://x.com/ApePePe_on_Ape" target="_blank">
          <img src="/images/dog.gif"><img src="/images/cross.gif"><img src="/images/wallet.gif"></img><br><img src="/images/lovebar1.gif" height="120px">
        </a>
      </div>
      Infinite visitors
      <br>
      <img src="/images/counter.gif">
      <br><img src="/images/jjj.gif">
      <br>
      <br>
    </div>
    <img src="/images/side_f.gif">
    <v-floating />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import http from '../services/httpService'
import mainContractService from '../services/mainContractService'
import VLoadingText from '../components/LoadingText.vue'
import VFloating from '../components/Floating.vue'

export default {
  name: 'Mint',
  components: {
    VLoadingText,
    VFloating,
  },
  data() {
    return {
      loading: true,
      minting: false,
      amount: 1,
      signature: null,
      balance: 0,
    }
  },
  computed: {
    ...mapState([
      'ready',
      'accountAddress',
      'totalSupply',
      'isPublicSalesActive',
      'isWhitelistSalesActive',
      'isServerDown'
    ]),
    ...mapGetters([
      'isConnected',
      'isValidNetwork'
    ]),
    mintLeft() {
      return this.maxPerAccount - this.balance
    },
    isWhitelisted() {
      return !!this.signature
    },
    unitsLeft() {
      return this.totalUnits - this.totalSupply
    },
    soldOut() {
      return this.unitsLeft == 0
    },
    totalUnits() {
      return process.env.VUE_APP_TOTAL_UNITS
    },
    mintPrice() {
      return process.env.VUE_APP_WHITELIST_MINT_PRICE * this.amount
    },
    networkName() {
      return process.env.VUE_APP_NETWORK_NAME
    },
    maxPerAccount() {
      return process.env.VUE_APP_MAX_WHITELIST_MINT_PER_ACCOUNT
    },
    isWhitelistable() {
      return process.env.VUE_APP_WHITELISTABLE == 'true'
    },
    formattedAccountAddress() {
      return `${this.accountAddress.slice(0, 6)}...${this.accountAddress.slice(-4)}`
    }
  },
  watch: {
    ready(v) {
      if (v && !this.accountAddress) {
        this.loading = false
      }
    },
    accountAddress(v) {
      this.signature = null
      this.amount = 1

      if (v) {
        if (this.isWhitelistable) {
          this.getWhitelistSignature()
        } else {
          this.loading = false
        }

        if (this.isValidNetwork) {
          this.getBalance()
        }
      }
    },
    isValidNetwork(v) {
      if (v && this.accountAddress) {
        this.getBalance()
      }
    }
  },
  methods: {
    ...mapMutations([
      'SET_SERVER_DOWN'
    ]),
    incrementAmount() {
      this.amount++

      if (this.amount > this.mintLeft) {
        this.amount = this.mintLeft
      }
    },
    decrementAmount() {
      this.amount--

      if (this.amount < 1) {
        this.amount = 1
      }
    },
    handleMintButtonClick() {
      if (!this.accountAddress) {
        this.$wallet.connect()
      } else if (!this.isValidNetwork) {
        this.$wallet.switchNetwork()
      } else {
        this.minting = true

        if (this.isWhitelisted) {
          this.handleWhitelistMint()
        } else {
          this.handleNormalMint()
        }
      }
    },
    handleNormalMint() {
      mainContractService.mint(this.amount, this.accountAddress)
        .then(() => {
          this.balance += this.amount
          this.amount = 1
          this.$toast.success('Mint completed. Check your NFT\'s.')
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          this.minting = false
        })
    },
    handleWhitelistMint() {
      mainContractService.whitelistMint(this.signature, this.accountAddress)
        .then(() => {
          this.signature = null
          this.amount = 1
          this.balance++
          this.$toast.success('Mint completed. Check your NFT\'s.')
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          this.minting = false
        })
    },
    getBalance() {
      mainContractService.balanceOf(this.accountAddress)
        .then(balance => {
          this.balance = +balance
        })
    },
    getWhitelistSignature() {
      this.loading = true

      const data = {
        account: this.accountAddress
      }

      http.post('/signature/whitelist/request', data)
        .then(response => {
          this.signature = response.data.signature
        })
        .catch(err => {
          if (!err.response) {
            this.SET_SERVER_DOWN(true)
            console.error(err)
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;


  }

  body {
    font-family: Roboto, sans-serif;
    justify-content: center;
    align-items: center;
    background-image: url(/images/bg.png);
    background-repeat: repeat;
    color: aquamarine;


  }
  .sidemint {
display: flex;
justify-content: center;
background-image: url(/images/star.gif);
  }
  #mint {
    text-align: center;
  }

  button:not(:disabled) {
    cursor: pointer;
  }

  .mint-button {
    width: 350px;
    height: 200px;
    border: 0;
    border-radius: 100px;
    font-weight: 900;
    font-size: 30px;
    transition: background-color 200ms;
  }

  .mint-button:not(:disabled):hover,
  .mint-button:focus {
    background-color: rgb(72, 255, 0);
  }

  .input-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .amount {
    width: 120px;
    height: 60px;
    font-size: 26px;
    text-align: center;
  }

  .input-arrows {
    display: flex;
    flex-direction: column;
  }

  .input-arrows button {
    width: 30px;
    height: 30px;
  }

  .mint-price {
    font-size: 22px;
    font-weight: 700;
  }

  .supply {
    font-size: 32px;
  }

  .reload-button {
    text-decoration: none;
    background-color: buttonface;
    width: 150px;
    height: 40px;
    line-height: 40px;
    border-radius: 100px;
    color: black;
  }

  .reload-button:hover,
  .reload-button:focus {
    background-color: rgb(255, 251, 0);
  }

  .ml-2 {
    margin-left: .5rem !important;
  }

  .mt-2 {
    margin-top: .5rem !important;
  }

  .mt-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem;
    color: rgb(255, 0, 255);
    font-size: 80px;
  }

  .mb-2 {
    margin-bottom: .5rem !important;
  }

  .d-block {
    display: block;
    color:#ddd;
    font-size: 60px;
  }

  .d-inline-block {
    display: inline-block;
  }

  .alert-success {
    color: rgb(0, 255, 0);

    font-size: 70px;
  }

  .alert-error {
    color: red;
  }

  .account-address .disconnect-wallet {
    cursor: pointer;
    color: red;
    font-size: 30px;
  }

  @media (min-width: 992px) {
    .mint-button {
      width: 310px;
      height: 55px;
    }
  }
</style>
