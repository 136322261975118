import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ready: false,
    accountAddress: null,
    networkId: null,
    totalSupply: null,
    isPublicSalesActive: false,
    isWhitelistSalesActive: false,
    isServerDown: false
  },
  getters: {
    isConnected(state, getters) {
      return state.accountAddress && getters.isValidNetwork
    },
    isValidNetwork(state) {
      return state.networkId == process.env.VUE_APP_NETWORK_ID
    }
  },
  mutations: {
    SET_READY(state, payload) {
      state.ready = payload;
    },
    SET_ACCOUNT_ADDRESS(state, payload) {
      state.accountAddress = payload;
    },
    SET_NETWORK_ID(state, payload) {
      state.networkId = payload;
    },
    SET_TOTAL_SUPPLY(state, payload) {
      state.totalSupply = payload;
    },
    SET_PUBLIC_SALES_ACTIVE(state, payload) {
      state.isPublicSalesActive = payload;
    },
    SET_WHITELIST_SALES_ACTIVE(state, payload) {
      state.isWhitelistSalesActive = payload;
    },
    SET_SERVER_DOWN(state, payload) {
      state.isServerDown = payload;
    }
  },
  actions: {},
  modules: {},
})
