import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueToast from 'vue-toast-notification';
import Wallet from './plugins/Wallet'
import 'vue-toast-notification/dist/theme-sugar.css';
import './filters'

Vue.config.productionTip = false

Vue.use(VueToast, {
  position: 'bottom'
})

Vue.use(Wallet)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
